export const PRICE_LAYOUT = {
  COMPACT: "compact",
  DEFAULT: "default",
};

export const PRICE_SIZE = {
  XXXS: "xxxs",
  XXS: "xxs",
  XS: "xs",
  S: "s",
  M: "m",
  // cambio para que sea compatible con lo anterior -> antes no definidos -> retrocompatibilidad
  // pendiente de eliminar el M_L y L_M
  M_L: null,
  L_M: null,
  L: "l",
  XL: "xl",
  XXL: "xxl",
  XXXL: "xxxl",
};

export const PRICE_DECORATION = {
  LINE_THROUGH: "line-through",
  UNDERLINE: "underline",
  NONE: "none",
};

export const PRICE_FONT_WEIGHT = {
  REGULAR: "font-regular",
  LIGHT: "font-light",
  BOLD: "font-bold",
};
